import React, { Component } from 'react';
import styled from 'styled-components'
import hall from './hall.png'
import {MAROON, DARKBLUE} from '../constants'

import scrollToElement from 'scroll-to-element'
import i18n from '../i18n'

const Section = styled.div`
  text-align: left;
  max-width: 100%;
  overflow-x: hidden;
`

const PhotoDesktop = styled.img`
  display:inline-block;
  width: 59%;
  margin-left: -7px;
  vertical-align: middle;

  @media screen and (max-width: 1200px){
    width: 66%;
    margin-left: -17%;
  }
  @media screen and (max-width: 1023px){
    display:none;
  }
`
const PhotoMobile = styled.img`
  width:100%;
  display: none;
  @media screen and (max-width: 1023px){
    display: block
  }
`

const TextBlock = styled.div`
  display:inline-block;
  width: 36%;
  vertical-align: middle;
  color: ${DARKBLUE}
  text-align: left;
  @media screen and (max-width: 1200px) {
    width: 47%;
  }
  @media screen and (max-width: 1023px){
    width: 100%;
  }
`

const TextBlockInner = styled.div`
  padding: 0 30px;
  @media screen and (max-width: 1023px){
    padding: 30px;
  }
`

const Title = styled.h1`
  color: ${MAROON};
  text-transform: uppercase;
  line-height: 1.2;
  letter-spacing: 2.5px;
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 15px;

`

const P = styled.p`
  padding-top: 15px;
  line-height: 1.4;
  strong{
    font-family:  'CommutersSans-Heavy';
    cursor: pointer;
    font-weight: normal;
  }
`


class DlaczegoMy extends Component {
  scroll = (id)=> {
    return scrollToElement(document.querySelector(`#${id}`), {
      offset: -50,
      duration: 300
    })
  }
  render() {
    return (
      <Section id='kim-jestesmy'>
        <PhotoDesktop src={hall}/>
        <TextBlock>
          <TextBlockInner>
          <Title>{i18n.t('our_profile')}</Title>
          <P><strong onClick = {()=>this.scroll('trzeciak')} >{i18n.t('drsergiusztrzeciak')}</strong> – {i18n.t('trzeciak_descshort')}</P>
          <P><strong onClick = {()=>this.scroll('chmal')} >{i18n.t('tomaszchmal')}</strong> – {i18n.t('chmal_descshort')}</P>
          <P>{i18n.t('ourprofile_3')}</P>
          </TextBlockInner>
        </TextBlock>
        <PhotoMobile src={hall}/>
      </Section>
    );
  }
}

export default DlaczegoMy
