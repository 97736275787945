import React, { Component } from 'react';
import styled from 'styled-components'
import {DARKBLUE, MAROON} from '../constants'
import logoFooter from './logo.png'
import phone from './phone.png'
import location from './location.png'
import mail from './mail.png'
import linkedin from './linkedin.png'
import i18n from '../i18n'

const Footer = styled.div`
  width: 100%;
  background-color: ${DARKBLUE}
  color: white;
  padding-top: 60px;
  padding-bottom: 180px;
  text-align: left;
  max-width: 100%;
  overflow-x: hidden;
  a{
    color: white;
  }
  @media screen and (max-width: 1023px){

    padding: 40px 30px 80px;
  }
`

const LogoSectionDesktop = styled.div`
  width: 50%;
  display: inline-block;
  vertical-align: middle;
  text-align: right;
  @media screen and (max-width: 1023px){
    display: none;
  }
`

const Logo = styled.img`
  padding-right: 60px;
  padding-top: 60px;
  width: 400px;
  @media screen and (max-width: 1023px){
    width: 80%;
    padding-right: 0;
    padding-top: 0;
  }
`

const Kontakt = styled.div`
  width: 50%;
  display: inline-block;
  vertical-align: middle;
  @media screen and (max-width: 1023px){
    width: 100%;

  }
`
const KontaktInner = styled.div`
  padding-left:48px;
  @media screen and (max-width: 1023px){
    padding: 0 0 40px;
    width: 300px;
    margin: 0 auto;
  }
`

const Title = styled.h1`
  padding: 40px 0;
  @media screen and (max-width: 1023px){
    padding: 0 0 20px;
  }
`

const Icon = styled.img`
  height: 30px;
  padding-right: 30px;
  vertical-align: middle;
  ${props=>props.location && `
    height: 40px;
    margin-left: -5px;
    vertical-align: top;
  `}
  ${props=>props.linkedin &&`
    height: 35px;
    margin-left: 0px;
  `}
`


const ContactOption = styled.div`
  font-size: 24px;
  font-weight: bold;
  height: 70px;
  padding-left: 10px;
  white-space: nowrap;
  @media screen and (max-width: 1023px){
    font-size: 18px;
    padding-left: 0;
  }
}`

const Span = styled.div`
  display: inline-block;
  vertical-align: middle;
`

const LogoSectionMobile = styled.div`
display: none;
@media screen and (max-width: 1023px){
  display: block;
  margin: 0 auto;
  text-align: center;
  width: 500px;
  max-width: 80%;
  padding: 0px 10px 100px;
  width: 80%;
  padding-top: 30px;
}
`

const Div = styled.div`
  margin: 0 auto;
`


class FooterSection extends Component {
  render() {
    return (
      <Footer id="kontakt"><LogoSectionDesktop>
          <Logo src={logoFooter}/>
        </LogoSectionDesktop>
        <Kontakt>
          <KontaktInner>
            <Title>{i18n.t('contact')}:</Title>
            <ContactOption><a href='mailto:biuro@trzeciakchmal.pl'><Icon src={mail}/><Span>biuro@trzeciakchmal.pl</Span></a></ContactOption>
            <ContactOption><a href="tel:+48222035965"><Icon src={phone}/><Span>+48 22 20 35 965</Span></a></ContactOption>
            <ContactOption>
              <a target='blank' href="https://goo.gl/maps/6geM15AsLY5v2dEEA">
                <Icon location src={location}/><Span>ul. Nowy Świat 61/1,<br/>00-042 Warszawa</Span>
              </a>
            </ContactOption>
            <div style={{paddingTop: '20px'}}><ContactOption><a href="https://www.linkedin.com/company/19142115"><Icon linkedin src={linkedin}/><Span>TRZECIAK | CHMAL</Span></a></ContactOption>
            </div>
             </KontaktInner>
        </Kontakt>
        <LogoSectionMobile>
          <Logo src={logoFooter}/>
        </LogoSectionMobile>
        </Footer>
    );
  }
}

export default FooterSection
