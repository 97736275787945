import React, { Component } from 'react';
import styled from 'styled-components'
import {DARKBLUE, getQueryVariable} from '../constants'
import scrollToElement from 'scroll-to-element'
import i18n from '../i18n'
import logo from './logo-menu.png'
const Menu = styled.div`
  max-width: 100%;
  overflow-x: hidden;
  @media screen and (max-width: 1023px) {
    display: none;
  }
  width: 100%;
  background-color: ${DARKBLUE}
  height: 48px;
  position: sticky;
  top: 0;
  z-index: 10;
  overflow: hidden;
`

const Option = styled.a`
  color: white;
  font-size: 16px;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  text-decoration: none;
  padding: 0 20px;
  line-height: 48px;
  cursor: pointer;
  vertical-align: middle;
  ${props => props.big &&
    `font-size: 28px;

  @media screen and (max-width: 1200px) {
    font-size: 0;
  }
    img {
      margin-top: -5px;
      vertical-align: middle;
      padding-right:5px;
    }
    `
  }
`

const OptionLang = styled.span`
  color: white;
  font-size: 16px;
  color: rgb(255, 255, 255);
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0 20px;
  line-height: 48px;
  cursor: pointer;
  color: #abaab2;
`

const A = styled.a`
  color: #abaab2;
  text-decoration: none;
  font-weight: ${props => props.isSelected ?  'bold': 'normal'}
`

const scrollToSection = (ev, id) => {
  ev.preventDefault()
  return scrollToElement(document.querySelector(`#${id}`), {
    offset: 0,
    duration: 300
  });
}


class MenuSection extends Component {

  isEnglishVersion = () => {
    return getQueryVariable('lang') === 'en'
  }

  render() {
    const eng = this.isEnglishVersion()
    return (
      <Menu>
        <Option big onClick = {ev => {scrollToSection(ev, 'trzeciakchmal')}}>
          <img src={logo}/>Trzeciak Chmal
        </Option>
        <Option onClick = {ev => {scrollToSection(ev, 'kim-jestesmy')}}>{i18n.t('our_profile')}</Option>
        <Option onClick = {ev => {scrollToSection(ev, 'dlaczego-my')}}>{i18n.t('why_us')}</Option>
        <Option onClick = {ev => {scrollToSection(ev, 'co-oferujemy')}}>{i18n.t('what_we_offer')}</Option>
        <Option onClick = {ev => {scrollToSection(ev, 'kontakt')}}>{i18n.t('contact')}</Option>
        <OptionLang>
          <A href='/' isSelected={!eng}>
            PL
          </A> |
          <A href='/?lang=en'isSelected={eng}> EN</A>
        </OptionLang>
      </Menu>
    );
  }
}

export default MenuSection
