import React, { Component } from 'react'
import styled from 'styled-components'
import {MAROON, DARKBLUE} from '../constants'
import tomaszChmal from './tomaszchmal.png'
import sergiuszTrzeciak from './sergiusztrzeciak.png'
import i18n from '../i18n'

const Section = styled.div`

font-size: 0;
max-width: 100%;
overflow-x: hidden;
`

const Text= styled.div`
  font-size: 16px;
  display: inline-block;
  vertical-align: top;
  width: 47%;

  @media screen and (max-width: 1200px) {
    width: 58%;
  }


  @media screen and (max-width: 1023px){
    width: 100%;
    padding: 50px 15px 50px;
  }

  ${props=> props.right && `
    padding-top: 25px;
  `}
`
const TextInner= styled.div`
  padding: 0 15px;
`

const Presentation= styled.div`
  text-align: ${props=> props.left? 'left': 'right'};

  ${props=> props.left? `
  img{
    padding-right: 15px;
  }
  `: `
  img{
    padding-left: 15px;
  }`}
  text-align: ${props=> props.left? 'left': 'right'}
  text-align: ${props=> props.left? 'left': 'right'}

  @media screen and (max-width: 1023px){
    text-align: left;
    img{
      padding: 0;
    }
  }
`
const TitlePres= styled.h1`
  margin-top: 0;
`
const ImageContainer= styled.div`
  display: inline-block;
  width: 50%;
  vertical-align: bottom;
  @media screen and (max-width: 1200px) {
  ${props=> props.left? `
    width: 67%;
    margin-left: -26%;
  `: `
    width: 67%;
    margin-right: -26%;
  `}
  }
  @media screen and (max-width: 1023px){
    width: 100%;
    margin: 0 auto;
  }
`

const ImageContainerDesktop= styled.div`
  display: inline-block;
  width: 50%;

  @media screen and (max-width: 1200px) {
  ${props=> props.left? `
    width: 67%;
    margin-left: -26%;
  `: `
    width: 67%;
    margin-right: -26%;
  `}
  }
  @media screen and (max-width: 1023px){
    width: 100%;
    margin: 0 auto;
    display: none;
  }
`
const ImageContainerMobile= styled.div`
  display:none;
  @media screen and (max-width: 1023px){
    width: 100%;
    margin: 0 auto;
    display: block;
  }
`

const Image= styled.img`
  box-sizing: border-box;
  width: 100%;
  height: auto;
`

const A = styled.a`
  text-decoration: none;
  color: ${MAROON}
`

class CoOferujemySection extends Component {
  constructor(){
    super()
    this.state = {
      activePosition: 0,
    }
  }
  render() {
    return (
      <Section>
        <Presentation left id='trzeciak'>
          <ImageContainer left>
            <Image src={sergiuszTrzeciak} />
          </ImageContainer>
          <Text>
            <TextInner>
            <TitlePres>{i18n.t('drsergiusztrzeciak')}</TitlePres>
            <p >{i18n.t('trzeciak_1')}</p>
            <p>{i18n.t('trzeciak_2')}</p>
            <p dangerouslySetInnerHTML={{__html: i18n.t('trzeciak_3')}}></p>
          </TextInner>
          </Text>
        </Presentation>
        <Presentation right id='chmal'>
        <ImageContainerMobile>
          <Image src={tomaszChmal}  style={{marginRight: '30px'}}/>
        </ImageContainerMobile>
          <Text right>

          <TextInner>
            <TitlePres>{i18n.t('tomaszchmal')}</TitlePres>
            <p>{i18n.t('chmal_1')}</p>
            <p>{i18n.t('chmal_2')}</p>

          </TextInner></Text>


          <ImageContainerDesktop>
            <Image src={tomaszChmal}  style={{marginRight: '30px'}}/>
          </ImageContainerDesktop>
        </Presentation>
      </Section>
    );
  }
}

export default CoOferujemySection
