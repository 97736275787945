import React, { Component } from 'react'
import styled from 'styled-components'
import Timelapse from './timelapse.mp4'
import logo from './logo.png'
import videoPlaceholder from './video-placeholder.jpg'
// import videoImage from './video-image.jpg'


const Section = styled.div`
  width: 100%;
  position: relative;
  max-height: calc(100vh - 48px);
  max-width: 100%;
  height: 56vw;
  overflow: hidden;
  background: url(${videoPlaceholder}) no-repeat center;
  background-size: cover;
  @media screen and (max-width: 1023px) {
    margin-top: 48px;
  }
`

const Video = styled.video`
  width: 100%;
  display: block;
`


const Logo = styled.img`
  left: 50%;
  top: 53%;
  width: 52%;
  transform: translate(-50%, -50%);
  position: absolute;

  @media screen and (max-width: 1023px) {
    width: 75%;
  }
`


class VideoSection extends Component {
  constructor(){
    super()
    this.video = React.createRef()
  }
  componentDidMount(){
    const video = document.getElementById('video')
    const interval = setInterval (()=>{
      const isPlaying = video.currentTime === 0 && video.readyState > 2;
      if (!isPlaying) {
        video.play();
        clearInterval(interval)
      }
    }, 2000)

  }
  render() {
    return <Section id='trzeciakchmal'>
      <video
        style={{width: '100%', display: 'block'}} loop ref={this.video} id='video' autoPlay="autoplay" defaultmuted='true' muted={true} playsInline preload="auto" >
        <source src={Timelapse} type="video/mp4"/>
      </video>
      <Logo src={logo} alt="Regulatory and strategic advisory"/>
    </Section>
  }
}

export default VideoSection
