import React, { Component } from 'react';
import styled from 'styled-components'
import { DARKBLUE, MAROON } from '../constants';
import i18n from '../i18n'

const Section = styled.div`
  background-color: ${DARKBLUE};
  color: white;
  text-align: left;
  padding: 60px;
  max-width: 100%;
  overflow-x: hidden;
  @media screen and (max-width: 1023px){
    padding: 50px 30px 40px;
  }
`

const TextBlock = styled.div`
  max-width: 1024px;
  margin: 0 auto;
`

const Title = styled.h1`
  color: ${MAROON};
  font-size: 16px;
  font-weight: bold;
  line-height: 1.2;
  padding-bottom: 20px;
}

`

const OL = styled.ol`
  padding-left: 80px;

  @media screen and (max-width: 1023px){
    padding-left: 25px;
  }
  li {
    text-align: left;
    font-size: 22px;
    line-height: 36px;
    padding: 10px 0;
    font-family:  'CommutersSans-Heavy'
  }

  @media screen and (max-width: 1023px){
    li {
      line-height: 28px;
      font-size: 20px;
    }
  }
`

const LI = styled.li``

class DlaczegoMy extends Component {
  render() {
    return (
      <Section id='dlaczego-my'>
        <TextBlock>
          <Title>{i18n.t('why_us')}</Title>
          <OL>
            <LI>{i18n.t('why_us_1')}</LI>
            <LI>{i18n.t('why_us_2')}</LI>
            <LI>{i18n.t('why_us_3')}</LI>
            <LI>{i18n.t('why_us_4')}</LI>
            <LI>{i18n.t('why_us_5')}</LI>
          </OL>
        </TextBlock>
      </Section>
    );
  }
}

export default DlaczegoMy;
