import React, { Component } from 'react';
import styled from 'styled-components'
import {DARKBLUE, getQueryVariable} from '../constants'
import scrollToElement from 'scroll-to-element'
import i18n from '../i18n'
import logo from './logo-menu.png'

const Menu = styled.div`
  max-width: 100%;
  overflow-x: hidden;
  @media screen and (max-width: 1023px) {
    display: block;
  }
  display:none;
  width: 100%;
  background-color: ${DARKBLUE}
  height: 48px;
  position: fixed;
  top: 0;
  z-index:100;
  color: white;
`

const Option = styled.a`
  color: white;
  font-size: 16px;
  color: rgb(255, 255, 255);
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0 20px;
  display: block;
  line-height: 48px;
  cursor: pointer;
`

const Left = styled.div`
  display:inline-block;
  text-align: left;
  width: 50%;
  line-height: 48px;
  padding-left: 24px;
  vertical-align: top;
  white-space: nowrap;
`

const Right = styled.div`
  cursor: pointer;
  padding-right: 20px;
  display:inline-block;
  text-align: right;
  font-size: 30px;
  width: 50%;
  line-height: 48px;
  vertical-align: top;
`
const Div = styled.div`
  height: 48px;
`
const Nav = styled.div`
  position:fixed;
  width:100%;
  height:100%;
  opacity: ${props=> props.isVisible ? 1: 0};
  transition: opacity ease-in 300ms;
  pointer-events: ${props=> props.isVisible ? 'all' : 'none'};
  background: ${DARKBLUE};
  z-index:10001;
  padding-top: 30px;
`

const Img = styled.img`
  padding-right:5px;
  vertical-align: middle;
  margin-top: -3px;
`



const OptionLang = styled.span`
  color: white;
  font-size: 16px;
  color: rgb(255, 255, 255);
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0 20px;
  line-height: 48px;
  cursor: pointer;
  color: #abaab2;
`

const A = styled.a`
  color: #abaab2;
  text-decoration: none;
  font-weight: ${props => props.isSelected ?  'bold': 'normal'}
  padding: 0 10px;
`


class MenuSection extends Component {
  constructor(){
    super()
    this.state={
      isVisibile: false,
    }
  }


 scrollToSection = (ev, id) => {
  ev.preventDefault()
  this.setState({isVisible:false})
    return scrollToElement(document.querySelector(`#${id}`), {
      offset: 0,
      duration: 300
    });
  }

  toggleVisible = () => {
    this.setState({isVisible:!this.state.isVisible})
  }

  isEnglishVersion = () => {
    return getQueryVariable('lang') === 'en'
  }

  render() {

    const eng = this.isEnglishVersion()
    return (
      <Menu>
        <Div>
          <Left onClick = {ev => { this.scrollToSection(ev, 'trzeciakchmal')}}><Img src={logo}/>Trzeciak Chmal</Left>
          <Right onClick={this.toggleVisible}>
            {this.state.isVisible
              ?<div>×</div>
              :<div>☰</div>}
          </Right>
        </Div>
        <Nav isVisible={this.state.isVisible}>
          <Option onClick = {ev => {this.scrollToSection(ev, 'kim-jestesmy')}}>{i18n.t('our_profile')}</Option>
          <Option onClick = {ev => {this.scrollToSection(ev, 'dlaczego-my')}}>{i18n.t('why_us')}</Option>
          <Option onClick = {ev => {this.scrollToSection(ev, 'co-oferujemy')}}>{i18n.t('what_we_offer')}</Option>
          <Option onClick = {ev => {this.scrollToSection(ev, 'kontakt')}}>{i18n.t('contact')}</Option>
          <OptionLang>
          <A href='/' isSelected={!eng}>
            PL
          </A>  |  <A href='/?lang=en'isSelected={eng}> EN</A>
        </OptionLang>
        </Nav>
      </Menu>
    );
  }
}

export default MenuSection
