import React, { Component } from 'react';
import MetaTags from 'react-meta-tags'
import './App.css';
import {getQueryVariable} from './constants'
import Video from './video'
import Menu from './menu'
import MenuMobile from './menu/mobile'
import KimJestesmy from './kimJestesmy'
import DlaczegoMy from './dlaczegoMy'
import CoOferujemy from './coOferujemy'
import Presentations from './presentations'
import Footer from './footer'

class App extends Component {
  isEnglishVersion = () => {
    return getQueryVariable('lang') === 'en'
  }
  render() {
    return (
      <div className="App">

          <MetaTags>
            <title>Trzeciak|Chmal - Regulatory and Strategic Advisory</title>
            {!this.isEnglishVersion()
            ? <meta name="description" content="Zapewniamy strategiczne podejście do problemów biznesowych. Integrujemy usługi prawne, komunikację strategiczną, Public Affairs i Public Relations." />
            : <meta name="description" content="We provide a comprehensive strategic approach to business problems. We integrate Regulatory Services, Strategic Communication, Public Affairs and Public Relations.            " />
            }
          </MetaTags>
        <MenuMobile/>
        <Video/>
        <Menu/>
        <KimJestesmy/>
        <DlaczegoMy/>
        <CoOferujemy/>
        <Presentations/>
        <Footer/>
      </div>
    );
  }
}

export default App;
