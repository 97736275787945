import i18next from 'i18next';

import pl from './pl.json'
import en from './en.json'
import {getQueryVariable} from './constants'

i18next
  .init({
    interpolation: {
      // React already does escaping
      escapeValue: false,
    },
    lng: getQueryVariable('lang') === 'en'? 'en' : 'pl', // 'en' | 'pl'
    // Using simple hardcoded resources for simple example
    resources: {
      en: {
        translation: en,
      },
      pl: {
        translation: pl,
      },
    },
  })

export default i18next