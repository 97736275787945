import React, { Component } from 'react'
import styled from 'styled-components'
import {MAROON, DARKBLUE} from '../constants'
import Sliderr from "react-slick";
import i18n from '../i18n'

const offers = [
  {
    title: i18n.t('public_affairs'),
    content: i18n.t('public_affairs_desc'),
  },
  {
    title: i18n.t('public_relations'),
    content: i18n.t('public_relations_desc'),
  },
  {
    title: i18n.t('strategic_consulting'),
    content: i18n.t('strategic_consulting_desc'),
  },
  {
    title: i18n.t('regulatory_affairs'),
    content: i18n.t('regulatory_affairs_desc'),
  },
]



const Chooser = styled.div`
  background-color: ${MAROON};
  height: 45px;
  color: white;
  text-transform: uppercase;
  line-height: 45px;
  cursor: pointer;
  white-space: nowrap;
  margin: 50px auto 40px;

  @media screen and (max-width: 1023px){
    margin: 0 auto;
  }
`
const ChooserInner = styled.div`
  max-width: 892px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
`

const Title = styled.h1`

  padding: 0 100px;
  margin: 0 auto;
  max-width: 1120px;
  @media screen and (max-width: 1023px) {
    padding: 0;
    margin-bottom: 30px;
  }
`
const Slider = styled.div`
  display: block;
  @media screen and (max-width: 1023px) {
    display: none;
  }

`
const P = styled.div`
  padding: 0 100px;
  margin: 0 auto;
  max-width: 1120px;
  @media screen and (max-width: 1023px) {
    padding: 0;
  }
`
const Content = styled.div`
  padding: 0 100px;
  max-width: 1120px;
  font-size: 18px;
  line-height: 1.667;
  text-align: left;

  min-height: 150px;
  margin: 40px auto 20px;
  font-weight: bold;
  @media screen and (max-width: 1023px) {
    padding: 0;
    margin: 20px 0 0;
  }

`
const Section = styled.div`
  text-align: left;
  padding: 60px 0;

  max-width: 100%;
  overflow-x: hidden;

  @media screen and (max-width: 1023px){
    padding: 50px 30px 20px;
  }
`

const SectionInner = styled.div`
  margin: 0 auto;
  text-align: left;
`

const Span = styled.span`
  padding: 0 20px;
  font-weight: 600;
  font-size: 14px;
  color: ${props => props.isSelected ? DARKBLUE: 'white'};
  cursor: pointer;
  &:first-child {
    padding-left: 0;
  }
  &:last-child{
    padding-right: 0;
  }
`

const SpanMobile = styled.span`
  padding: 0 20px;
  font-weight: 600;
  font-size: 14px;
  color: white;
  cursor: pointer;

  background-color: ${MAROON};
  height: 45px;
  color: white;
  text-transform: uppercase;
  line-height: 45px;
  cursor: pointer;
  white-space: nowrap;
  display: block;
`

const StyledSlider = styled(Sliderr)`
  display: none;
  margin: 40px 0;
  @media screen and (max-width: 1023px) {
    display: block;
  }
  .slick-next, .slick-prev{
    top: 25px;
  }
  .slick-next:before, .slick-prev:before {
    color: ${MAROON};
    top: 0;
  }
  .slick-dots {
    bottom: -40px;
  }
  .slick-dots li{
    margin: 0 15px;
  }
  .slick-dots li button:before {
    font-size: 10px;
  }

`

class SimpleSlider extends React.Component {

  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <StyledSlider {...settings}>
        {offers.map(offer=>
          <div key={offer.title}>
            <SpanMobile>{offer.title.toString()}</SpanMobile>
            <Content dangerouslySetInnerHTML={{__html: offer.content.toString()}}/>
          </div>
          )}
      </StyledSlider>
    );
  }
}

class CoOferujemySection extends Component {
  constructor(){
    super()
    this.state = {
      activePosition: 0,
    }
  }
  render() {
    return (
      <Section id='co-oferujemy'>
        <SectionInner>
          <Title>{i18n.t('what_we_offer')}</Title>
          <P dangerouslySetInnerHTML={{__html: i18n.t('what_we_offer_desc')}}></P>
          <Slider>
            <Chooser>
              <ChooserInner>
              {offers.map((offer, index)=>(
                <Span
                  key={offer.title}
                  isSelected={index===this.state.activePosition}
                  onClick={(e)=> {this.setState({activePosition: offers.findIndex(offer => {
                    return offer.title.toString().toUpperCase() === e.target.innerText.toUpperCase()
                  })})}}>
                    {offer.title.toString()}
                </Span>
              ))}
              </ChooserInner>
            </Chooser>
            <Content dangerouslySetInnerHTML={{__html: offers[this.state.activePosition].content}}>
            </Content>
          </Slider>
          <SimpleSlider/>
          </SectionInner>
      </Section>
    );
  }
}

export default CoOferujemySection
